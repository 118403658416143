import styled from 'styled-components';

export const NameWrapper = styled.div`
    clear: both;
    overflow: hidden;
    margin-bottom: 1.3em;
    
    .item-left {
      width: 49%;
      float: left;
    }
    
    .item-right {
      width: 49%;
      float: right;
    }
`;

const SectionWrapper = styled.div`
  width: 100%;
  padding: 0em 0 2em;
  position: relative;
  overflow: hidden;
  background-color: #ffc107;
  color: #fff!important;
 
   @media only screen and (max-width: 667px) {
      padding: 0 0 25px!important;
      
    .blog__image_container  {
      margin-bottom: 0;;
    }
  }
  
  h1 {
    padding-bottom: 0.25em;
    margin-bottom: 0;
    font-size: 2.5em;
    font-weight: normal;
      
    @media only screen and (max-width: 667px) {
        font-size: 1.5em;
    }
  }
  
  .post-meta-single {
    color: #345e3b!important;
    
    @media only screen and (max-width: 667px) {
        margin-top: 0.5em
    }
  
    .tag-base {
      height: 28px;
      line-height: 28px;
      padding: 0 14px;
      display: inline-block;
      border-radius: 3px;
      font-size: 15px;
      color: #fff;
      float:left;
    }
  
    .date {
        font-weight: bold;
        padding-left: 1em;
        float: left;
        padding-top: 0.1em;
               
        @media only screen and (max-width: 667px) {
            padding-left: 0;
            display: block;
        }
    }
  }

  
  .demo-bg {
      opacity: 0.08;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0,-65%);
      width: 100%;
      height: auto;
  }
  
  @media only screen and (max-width: 1440px) {
    margin: 70px 0;
    padding: 120px 0 90px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1360px) {
    margin: 0;
    padding: 0 0 70px;
    .row {
      > .col {
        &:first-child {
          width: 53%;
        }
        &:last-child {
          width: 47%;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    margin: 0;
    padding: 0 0 50px;
  }
  @media only screen and (max-width: 667px) {
    .row {
      > .col {
        width: 100%;
      }
    }
  }
`;

export default SectionWrapper;
