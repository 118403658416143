import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'common/components/UI/Container';
import SectionWrapper from './donateSection.style';
import {Link} from "gatsby";
import moment from "moment";

const DonateSection = ({ data }) => {
  console.log(data);
  return (
    <SectionWrapper id="support-us-section">
      <Container width="1260px">
        <h1 className="page-title">{ data.datoCmsNews.title }</h1>
        {
          data.datoCmsNews.category && (
            <div className="post-meta-single">
              <ul>
                <li>
                  <Link
                    className="tag-base tag-blue"
                    to="/"
                    style={{ background: data.datoCmsNews.category.color.hex }}>
                    { data.datoCmsNews.category.name }
                  </Link>
                </li>
                <li className="date"><i className="fa fa-clock-o" />{ moment(data.datoCmsNews.meta.createdAt).format('MMMM Do YYYY') }</li>
              </ul>
            </div>
          )
        }
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    pl: '15px',
    pr: '15px',
    mb: '30px',
    color: '#fff',
  },
};

export default DonateSection;
