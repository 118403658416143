import React, { Fragment } from 'react';
import { Widget } from '@typeform/embed-react'

import {ThemeProvider} from "styled-components";
import CookieNotice from "../components/CookieNotice";
import { charityTheme } from 'common/theme/charity';
import Footer from 'components/Footer';
import Navbar from 'components/NavbarNews';
import {
  GlobalStyle,
  CharityWrapper,
} from 'containers/Charity/charity.style';
import { ResetCSS } from 'common/assets/css/style';
import Seo from 'components/seo';
import Container from "../common/components/UI/Container";
import ESU from '../components/ESU';
import TitleBlock from "../components/News/title-block";

const BlogDetailsPage = ({ data }) => {
  return(
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo title="Home" />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Navbar/>
          <TitleBlock data={{ datoCmsNews: { title: 'Our Residents Survey' } }} />
          <Container width='1260px'>
            <div style={{ height: '700px' }}>
              <Widget id="Bi6iA7lw" style={{ width: '100%', height: '700px' }} className="my-form" />
            </div>
          </Container>
          <ESU data={{ title: 'Subscribe to updates', description: 'Get the latest news and updates from Godalming & Villages Residents'}} />
          <Footer />
          <CookieNotice />
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  )
}

export default BlogDetailsPage
